<template>
  <div class="row">
    <div class="col-md-12">
      <!-- Modal QR Code -->
        <div class="modal modal-fullscreen d-block">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body d-flex flex-column text-center">
                <div class="d-flex justify-content-center mb-3">
                  <div class="text-center">
                    <img :src="mcu.biodata.foto" alt="Foto Anda" class="foto-selfie">
                    <span class="fs-18 fw-700 d-block mt-3">{{ mcu.biodata.nama_pasien }}</span>
                  </div>
                </div>

                <div class="img-qrcode" id="modal-qrcode"></div>

                <router-link
                  :to="{name: 'MCUKkp', params: {kode: mcu.id_mcu}}"
                >
                  <img src="@/assets/icon/close-circle.svg" alt="Tutup" role="button">
                </router-link>
              </div>
            </div>
          </div>
        </div>
      <!-- Modal -->
    </div>
  </div>
</template>

<script>
import { mcu } from '@/core/mcu'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { qrcanvas } from 'qrcanvas'

export default {
  setup () {
    const route = useRoute()
    const generateQr = () => {
      const modal = document.getElementById('modal-qrcode')

      const canvas = qrcanvas({
        cellSize: 13,
        data: route.params.kkp,
        foreground: [
          { style: '#007A98' }
        ]
      })

      modal.innerHTML = '' // Reset dulu semuanya
      modal.appendChild(canvas) // Baru inject qrcode nya
    }

    onMounted(() => {
      generateQr()
    })

    return {
      mcu
    }
  }
}
</script>

<style lang="scss" scoped>
.foto-selfie {
  border-radius: 100px;
  width: 84px;
  height: 84px;
}
</style>
